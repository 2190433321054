import React, {ReactNode, useState} from "react";

interface TabProps {
  tabs: string[];
  children: ReactNode[];
}

export const TabLayout: React.FC<TabProps> = ({tabs, children}) => {
  const [activeTab, setActiveTab] = useState(0);
  if (!children || children.length === 0) {
    return null;
  }
  return (
    <div>
      <div className="tab-border flex justify-between items-center border-y border-primary-bg">
        {tabs.map((tab, index) => (
          <button
            key={index}
            className={`py-3 sm:py-5 px-2 sm:px-4 text-xl sm:text-2xl font-bold ${
              index === activeTab
                ? 'text-primary-text' : 'text-accent-bg'}`}
            onClick={() => setActiveTab(index)}
          >
            {tab}
          </button>
        ))}
      </div>
      <div className="py-4 bg-white">{children[activeTab]}</div>
    </div>
  );
};