import React from 'react';
import {GoAlertFill} from "react-icons/go";

export type ErrorsType = {
  [key: string]: string[]
}

export interface ErrorListProps {
  name: string,
  errors?: null | ErrorsType;
}

export const ErrorList: React.FC<ErrorListProps> = ({name, errors}) => {
  return (
    <>
      { errors && errors[name] && <ul className='bg-accent-main mt-1 text-sm'>{
          errors[name].map((val, index) => (
            <li className="px-2 flex items-center text-white" key={index}><GoAlertFill className="mr-1"/>{val}</li>
          ))
        }</ul>}
    </>
  )
}