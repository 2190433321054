import React from "react"
import { Link, Outlet } from "react-router-dom";
import {FaHome,FaHistory, FaBuilding, FaRegCreditCard, FaBars} from "react-icons/fa";

export const MenuFooter: React.FC = () => {
    return(
        <div>
            <Outlet />
            <div className="fixed bottom-0 max-[820px]:w-full w-[820px] bg-white">
                <div className="border-t-2 border-primary-bg px-4 py-3">
                    <div className="flex justify-between">
                        <Link
                          to=""
                          className="flex flex-col items-center text-primary-dark">
                            <FaHome className="h-8 w-8"/>
                            <span className="text-[10px]">資産状況</span>
                        </Link>
                        <Link
                          to="/trading-history"
                          className="flex flex-col items-center text-primary-dark">
                            <FaHistory className="h-8 w-8"/>
                            <span className="text-[10px]">取引履歴</span>
                        </Link>
                        <div className="p-1 bg-white shadow-accent-bg shadow-[-0px_-1px] -mt-6 rounded-full">
                        <Link
                          to="/fund"
                          className="flex flex-col items-center p-3 bg-accent-main hover:bg-[#FFEBF1] text-white hover:text-accent-main h-16 w-16 rounded-full justify-center ">
                            <FaBuilding className="h-8 w-8"/>
                            <span className="text-[10px]">募集中</span>
                        </Link>
                        </div>
                        <Link
                          to="/transaction"
                          className="flex flex-col items-center text-primary-dark">
                            <FaRegCreditCard className="h-8 w-8"/>
                            <span className="text-[10px]">入出金</span>
                        </Link>
                        <Link
                          to="/menu"
                          className="flex flex-col items-center text-primary-dark hover:text-primary-main">
                            <FaBars className="h-8 w-8"/>
                            <span className="text-[10px]">メニュー</span>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}