import React from "react";

interface FundSectionProp {
  title?: string;
  text?: string;
  fundsCount?: string | number;
  children?: React.ReactNode;
  border?:string;
}
export const InvestmentFundSection: React.FC<FundSectionProp> = ({
                                                                   title,
                                                                   fundsCount,
                                                                   text,
  border,
                                                                   children}) => {
  return (
    <div className='my-10 px-4'>
      <div className="flex justify-between items-center pb-2">
        <h2 className={`${text} text-xl`}>{title}</h2>
        <span className="text-accent-bg text-lg">{fundsCount}件</span>
      </div>
      <div className={`${border}`}>
        {children}
      </div>
    </div>
  )
}