import React from "react";
import { numberWithCommas } from '../../utlis/numbers';
import {Link} from "react-router-dom";

interface FundListProp {
  name?: string;
  slug?: string;
  image?: string;
  investmentAmount?:number;
  investmentUnit?:number;
  plannedOperationCompletion?: string;
  operationCompletionDate?: string;
  finalDividend?: string;
  cancel?: string;
  className?: string;
}


export const InvestmentFundList: React.FC<FundListProp> = ({
                                                             name,
                                                             slug='',
                                                             image,
                                                             investmentAmount,
                                                             investmentUnit,
                                                             plannedOperationCompletion,
                                                             operationCompletionDate,
                                                             finalDividend,
                                                             cancel
                                                           }) => {
  return (
    <Link to={slug} >
      <div
        className={`${image ? 'bg-white shadow-md rounded  px-2 py-2 my-3' : 'py-3'} border-b flex justify-start items-center gap-5`}>
        {image &&
            <figure className="h-[105px] w-[105px] border ">
                <img className="object-cover w-full" src={image} alt="text"/>
            </figure>
        }
        <div className="justify-self-start">
          <h2 className="text-xl font-normal mb-1">{name}</h2>
          {investmentAmount && <p className="text-secondary-text">出資額：{numberWithCommas(investmentAmount)}円 ({investmentUnit}口）</p>}
          {plannedOperationCompletion &&
              <p className="text-secondary-text">予定運用完了日：{plannedOperationCompletion}</p>}
          {operationCompletionDate && <p className="text-secondary-text">運用完了日：{operationCompletionDate}</p>}
          {finalDividend && <p className="text-secondary-text">最終配当+元本償還日：日{finalDividend}</p>}
          {cancel && <p className="text-secondary-text">キャンセル理由：{cancel}</p>}
        </div>
      </div>
    </Link>
  )
}