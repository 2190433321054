import React from "react";
import {NewsMinimumType} from "../../store/post";
import {Section} from "../../components/layouts/section";
import {FaNewspaper} from "react-icons/fa";
import {NewsData} from "./NewsData";
import {Link} from "react-router-dom";

export const HomeNews: React.FC<{posts: NewsMinimumType[]}> = ({posts}) => {
  return (
    <div className="pt-14 pb-5">
      <Section className="bg-secondary-dark text-primary-dark flex justify-between items-center py-4">
        <h2 className="text-xl flex-1">みつばちファンドからのお知らせ</h2>
        <p className="w-[38px] h-[38px]"><FaNewspaper className="w-[38px] h-[38px]"/></p>
      </Section>
      <Section className="my-3">
        <ul>
          {posts.length > 0 ? (
            posts.map((news,index) => (
              <NewsData
                key={index}
                slug={news.slug}
                title={news.title}
                created_date={news.created_date}
              />
            ))
          ) : (
            <p className="mt-4">データは利用できません。</p>
          )}
        </ul>
        <div className="flex items-center justify-center">
          <Link to="/news"
                className="border-2 rounded border-primary-dark text-primary-dark font-bold flex justify-center items-center text-center py-3 w-52 h-10 mt-8">お知らせ一覧</Link>
        </div>
      </Section>
    </div>
  )
}