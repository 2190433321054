import React from 'react';
import { ImageIcon } from '../../icons/ImageIcon';

interface FileInputProps {
  value?: string | ArrayBuffer | null;
  text?: string;
  label?: string;
  name?: string;
  required?: boolean;
  onChange?: (file: string | ArrayBuffer | null) => void;
}

const FileInput: React.FC<FileInputProps> = ({
                                               value,
                                               name,
                                               label,
                                               required,
                                               text,
                                               onChange
}) => {
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { files, name } = event.target;
    if (files && onChange) {
      for (let i = 0; i < files.length; i++) {
        const reader = new FileReader();
        reader.onload = () => {
          if (reader.readyState === 2) {
            onChange(reader.result);
          }
        };
        reader.readAsDataURL(files[i]);
      }
    }
  };

  return (
    <div className='items-center max-[400px]:w-full w-[400px]'>
      {text ? <p>{text}</p> : null}
      <label htmlFor={`fileInput-${name}`}
             className={`flex w-full items-center justify-center px-2 text-primary-text py-2.5 sm:py-4 text-base rounded ${value ? 'bg-secondary-bg' : 'bg-primary-bg'}`}>
        <ImageIcon className='h-6 w-6 mr-2' />
        {label}
      </label>
      <input
        id={`fileInput-${name}`}
        type='file'
        name={name}
        required={required}
        style={{ display: 'none' }}
        onChange={handleFileChange}
      />
    </div>
  );
};

export default FileInput;