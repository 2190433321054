import React, {useState} from "react";
import Image from '../../assets/noimage.jpg'
import {InvestmentFundSection} from "./InvestmentFundSection";
import {InvestmentFundList} from "./InvestmentFundList";
import {InvestmentStatusType} from "../../store/account";

const investmentFundStatus :InvestmentStatusType = {
 currentFunds: [
    {
      name: "みつばち3号ファンド",
      slug: "fund-a",
      image:Image,
      unit: 1000,
      amount_of_investment: 100000,
    },
   {
      name: "みつばち3号ファンド",
      slug: "fund-b",
      image:Image,
      unit: 1000,
      amount_of_investment: 100000,
    },
  ],
  finishedFunds: [
    {
      name: "みつばち3号ファンド",
      slug: "fund-c",
      amount_of_investment: 100000,
    },
    {
      name: "Fund A",
      slug: "fund-d",
      amount_of_investment: 100000,
    },
  ],
  canceledFunds: [
    {
      name: "Fund A",
      note: "fund-e",
    },
  ],
}

export const InvestmentStatus: React.FC = () => {
  const [state, setState] = useState<InvestmentStatusType>(investmentFundStatus);
  const [hasFunds, setHasFunds] = useState<boolean>(true);
  return (
    <>
      {
        !hasFunds ? <div>no data found</div> :
          <div>
            <div>
              pie Chart
            </div>
            {
              state?.currentFunds &&
                <InvestmentFundSection
                    title="出資済ファンド一覧"
                    fundsCount={state?.currentFunds.length}
                >
                  {
                    state.currentFunds.map((fund, index) => (
                      <InvestmentFundList
                        name={fund.name}
                        slug={`investment-status/${fund.slug}`}
                        investmentUnit= {fund.unit}
                        image={fund.image}
                        investmentAmount={fund.amount_of_investment}
                        plannedOperationCompletion="2026年12月31日"
                      />
                    ))
                  }
                </InvestmentFundSection>
            }
            {
              state?.finishedFunds &&
                <InvestmentFundSection
                    title="運用完了ファンド一覧"
                    border="border-b border-t-2"
                    fundsCount={state?.finishedFunds.length} >
                  {
                    state.finishedFunds.map((fund, index) => (
                      <InvestmentFundList
                        name={fund.name}
                        slug={`investment-status/${fund.slug}`}
                        operationCompletionDate="2023年3月14日"
                        finalDividend="2023年4月31日"
                        className="border-b"
                      />
                    ))
                  }
                </InvestmentFundSection>
            }
            {
              state?.canceledFunds &&
                <InvestmentFundSection
                    title="キャンセル済ファンド一覧"
                    border="border-b border-t-2"
                    text="text-accent-main"
                    fundsCount={state?.canceledFunds.length}
                >
                  {
                    state.canceledFunds.map((fund, index) => (
                      <InvestmentFundList
                        name="みつばち1号ファンド"
                        cancel="クーリングオフ"
                      />
                    ))
                  }
                </InvestmentFundSection>
            }
          </div>
      }
    </>
  )
}