import React from "react";
import {FaAddressCard} from "react-icons/fa";
import {Link} from "react-router-dom";

const MyNumberLink: React.FC = () => {
    return(
        <Link to={`/mynumber-registration`} className="border-b border-primary-main text-sm sm:text-base py-2 text-primary-light items-center flex justify-center gap-1">
            <span><FaAddressCard/></span>
            <p>マイナンバー登録はこちら</p>
        </Link>
    )
}
export default MyNumberLink;