import React from "react"
import {ErrorList, ErrorListProps} from "./Error";
import { GoAlertFill } from "react-icons/go";

interface SelectProps<T extends object>extends ErrorListProps {
    label?: string;
    labelKey: keyof T;
    valueKey: keyof T;
    width?: string;
    value?: string;
    required?: boolean;
    options: T [];
    onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
    placeholder?: string;
    className?: string;
    showRequired?:boolean;
    labelClass?: string;
}
const Select = <T extends object>({
                                      options,
                                      labelKey,
                                      valueKey,
                                      name,
                                      value,
                                      width,
                                      label,
                                      onChange,
                                      labelClass,
                                      errors,
                                      placeholder,
                                      className = '',
                                      showRequired,
                                      required = true,
                                  }: SelectProps<T>): React.ReactElement => {

    return(
        <div className={`${width} py-2`}>
            {label &&
                <label className={`${labelClass} flex`}>{label}
                    {showRequired &&
						<span className='text-accent-main text-sm'>※必須</span>
					}
                </label>
            }
            <div className={`relative border border-accent-bg rounded disabled:opacity-50 ${className} flex items-center`}>
                <select
                    className='w-full py-2.5 px-3 rounded focus:outline-none'
                    value={value}
                    onChange={onChange}
                    required={required}
                    name={name}
                    >
                    {placeholder && <option className="text-accent-bg" disabled value="">{placeholder}</option>}
                    {options.map((option, index) => (
                        <option key={index} value={option[valueKey] as string}>
                            {option[labelKey] as string}
                          </option>
                    ))}
                </select>
                <ErrorList name={name} errors={errors} />
            </div>
        </div>
    )
}

export default Select;


