import {MyPagePostsType, NewsType, PostPaginationType} from "../../../store/post";
import BaseService from "../base";

const API_URL = process.env.REACT_APP_GATEWAY_URL;

class PostService extends BaseService{
   getMyPageHomeNews() {
    return this.axios.get<MyPagePostsType>(API_URL + `/api/v1/user/posts/post/`)
      .then<MyPagePostsType>((res) => res.data)
      .catch((res) => { throw res; });
  }

  getMyPageNews(postType: "news", page:number, limit:number){
     return this.axios.get<PostPaginationType>(API_URL + `/api/v1/user/posts/post/${postType}/`, {params:{page:page, limit:limit}} )
       .then<PostPaginationType>((res)=>res.data)
       .catch((res) => {throw res});
  }

  getMyPageNewsDetails(postType: "news", slug:string){
     return  this.axios.get<NewsType>(API_URL + `/api/v1/user/posts/post/${postType}/${slug}/`)
       .then<NewsType>((res)=>res.data)
       .catch((res) => {throw res});
  }

}
export default PostService;